<template>
  <v-row>
    <v-col cols="12" lg="12" md="12" sm="12">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="value"
            small
            prepend-icon="mdi-calendar-range"
            readonly
            v-bind="attrs"
            v-on="on"
            persistent-hint
            :label="label"
            :rules="required ? [isValid.required] : []"
          ></v-text-field>
        </template>
        <v-date-picker
          no-title
          scrollable
          :value="value"
          @input="input($event)"
          :max="maxDate"
          :min="minDate"
          :ref="yearPicker ? 'picker' : null"
          @click:year="setYear($event)"
          :show-current="showCurrent"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: { type: String },
    label: { type: String },
    maxDate: { type: String, required: false },
    minDate: { type: String, required: false },
    yearPicker: { type: Boolean, required: false },
    yearOnly: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    showCurrent: { type: Boolean, required: false, default: false },
    requiredMessage: { type: String, required: false },
  },

  data() {
    return {
      menu: false,

      isValid: {
        required: (v) => !!v || this.requiredMessage,
      },
    };
  },

  methods: {
    input(event) {
      this.menu = false;
      this.$emit("input", event);
    },

    setYear(event) {
      if (this.yearOnly) {
        this.menu = false;
        this.$emit("input", event.toString());
      }
    },
  },
  computed: {},

  created() {},
  watch: {
    menu(val) {
      this.yearPicker
        ? val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"))
        : "";
    },
  },
};
</script>
<style>
.validation-error {
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
}
</style>
